// // import { useState, useCallback } from 'react';

// // const useChunkedUpload = (uploadFile, chunkSize) => {
// //   const [uploadProgress, setUploadProgress] = useState({});
// //   const [overallProgress, setOverallProgress] = useState(0);
// //   const [isUploading, setIsUploading] = useState(false);
// //   const [uploadComplete, setUploadComplete] = useState(false);

// //   const handleUpload = useCallback(async (files) => {
// //     setIsUploading(true);
// //     setUploadComplete(false);
    
// //     const totalFiles = files.length;
// //     let uploadedFiles = 0;

// //     const uploadPromises = Array.from(files).map(async (file) => {
// //       const totalChunks = Math.ceil(file.size / chunkSize);
// //       let uploadedChunks = 0;

// //       for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
// //         const start = chunkIndex * chunkSize;
// //         const end = Math.min(start + chunkSize, file.size);
// //         const chunk = file.slice(start, end);

// //         // Upload the chunk
// //         await uploadFile(file, chunk, chunkIndex);
// //         uploadedChunks++;

// //         // Update progress for the individual file
// //         const percent = Math.round((uploadedChunks / totalChunks) * 100);
// //         setUploadProgress((prev) => ({ ...prev, [file.name]: percent }));

// //         // Update overall progress
// //         const overallPercent = Math.round(((uploadedFiles + (uploadedChunks / totalChunks)) / totalFiles) * 100);
// //         setOverallProgress(overallPercent);
// //       }

// //       uploadedFiles++;
// //       // Final update for the uploaded file
// //       setUploadProgress((prev) => ({ ...prev, [file.name]: 100 }));
// //     });

// //     await Promise.all(uploadPromises);

// //     setIsUploading(false);
// //     setUploadComplete(true);
// //   }, [uploadFile, chunkSize]);

// //   return { handleUpload, uploadProgress, overallProgress, isUploading, uploadComplete };
// // };

// // export default useChunkedUpload;



// import { useState, useCallback } from 'react';

// const useChunkedUpload = (uploadFile, chunkSize) => {
//   const [uploadProgress, setUploadProgress] = useState({});
//   const [overallProgress, setOverallProgress] = useState(0);
//   const [isUploading, setIsUploading] = useState(false);
//   const [uploadComplete, setUploadComplete] = useState(false);

//   const handleUpload = useCallback(
//     async (files) => {
//       setIsUploading(true);
//       setUploadComplete(false);

//       const totalFiles = files.length;
//       let uploadedFiles = 0;

//       const uploadPromises = Array.from(files).map(async (file, fileIndex) => {
//         const totalChunks = Math.ceil(file.size / chunkSize);
//         let uploadedChunks = 0;

//         for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
//           const start = chunkIndex * chunkSize;
//           const end = Math.min(start + chunkSize, file.size);
//           const chunk = file.slice(start, end);

//           // Upload the chunk with fileIndex as file_id
//           await uploadFile(file, chunk, chunkIndex, fileIndex);
//           uploadedChunks++;

//           // Update progress for the individual file
//           const percent = Math.round((uploadedChunks / totalChunks) * 100);
//           setUploadProgress((prev) => ({ ...prev, [file.name]: percent }));

//           // Update overall progress
//           const overallPercent = Math.round(
//             ((uploadedFiles + uploadedChunks / totalChunks) / totalFiles) * 100
//           );
//           setOverallProgress(overallPercent);
//         }

//         uploadedFiles++;
//         // Final update for the uploaded file
//         setUploadProgress((prev) => ({ ...prev, [file.name]: 100 }));
//       });

//       await Promise.all(uploadPromises);

//       setIsUploading(false);
//       setUploadComplete(true);
//     },
//     [uploadFile, chunkSize]
//   );

//   return {
//     handleUpload,
//     uploadProgress,
//     overallProgress,
//     isUploading,
//     uploadComplete,
//   };
// };

// export default useChunkedUpload;




import { useState, useCallback } from 'react';
import useUploadActions from './useUploadActions';
import { determineFileType } from './commonServices';
import { fileFormat, removeExtension } from '../../utils/commonServices';

const useChunkedUpload = ( chunkSize) => {

  
  const [uploadProgress, setUploadProgress] = useState({});
  const [overallProgress, setOverallProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadedChunks, setUploadedChunks] = useState({}); // Store uploaded chunks
  const {fileUploadPreflight, uploadFile, fileUplaodPostflight} = useUploadActions()

  const handleUpload = useCallback( async (file) => {
      setIsUploading(true);
      setUploadComplete(false);
      let tempChunksArray = []
     // const totalFiles = files.length;
     // let uploadedFiles = 0;

   //   const uploadPromises = Array.from(files).map(async (file, fileIndex) => {

        const totalChunks = Math.ceil(file.size / chunkSize);
         // 1. Preflight request to get mediaId
      //    const fileType = determineFileType(file)
      //    const payloadData = {
      //     name: file?.name,
      //     content_type: fileType,
      //     size: file?.size,
      //     total_chunk_count: totalChunks,
      //    }
      // const mediaId = await fileUploadPreflight(payloadData);
      // if (!mediaId) {
      //   console.error(`Failed to get mediaId for file ${file.name}`);
      //   return;
      // }

      
      //  let uploadedChunksForFile = []; 

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
          const start = chunkIndex * chunkSize;
          const end = Math.min(start + chunkSize, file.size);
          const chunk = file.slice(start, end);

         const payloadData = {
          file:chunk,
          index: chunkIndex,
          file_name: `${removeExtension(file?.name)}_${chunkIndex}.${fileFormat(file?.name)}`,
          size:chunk?.size

         }

         tempChunksArray.push(payloadData);

        // 1. Preflight request to get mediaId
         const fileType = determineFileType(file)
         const preFlightPayload = {
          name: file?.name,
          content_type: fileType,
          size: file?.size,
          total_chunk_count: totalChunks,
          event_id: 2039
         }
      const mediaId = await fileUploadPreflight(preFlightPayload);
      if (!mediaId) {
        console.error(`Failed to get mediaId for file ${file.name}`);
        return;
      } else {

      }

          // await uploadFile(file, chunk, chunkIndex, fileIndex, payloadData);
          // uploadedChunksForFile.push(chunk); 

        
          // const percent = Math.round((uploadedChunksForFile.length / totalChunks) * 100);
          // setUploadProgress((prev) => ({ ...prev, [file.name]: percent }));

        
          // const overallPercent = Math.round(
          //   ((uploadedFiles + uploadedChunksForFile.length / totalChunks) / totalFiles) * 100
          // );
          // setOverallProgress(overallPercent);
        }



       // uploadedFiles++;
      
      //  setUploadProgress((prev) => ({ ...prev, [file.name]: 100 }));

      
     //   setUploadedChunks((prev) => ({ ...prev, [file.name]: uploadedChunksForFile }));
     //   await fileUplaodPostflight()
    //  });

      // await Promise.all(uploadPromises);

      // setIsUploading(false);
      // setUploadComplete(true);
      setChunks(tempChunksArray)
    },
    [uploadFile, chunkSize]
  );

  return {
    handleUpload,
    uploadProgress,
    overallProgress,
    isUploading,
    uploadComplete,
    uploadedChunks, 
  };
};

export default useChunkedUpload;
