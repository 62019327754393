// import { useDispatch, useSelector } from "react-redux";
// import { showSnackBar } from "../../utils/commonServices";
// import {  watchVideoApi } from "../../services/api";




// export default ()=> {
 
//   const dispatch = useDispatch();
//   const commonReducer = useSelector(state=> state.root.commonReducer)
 

//       const uploadFile = (file, chunk, chunkIndex ) => {

//         const formData = new FormData();
//         formData.append('file', chunk, `${file.name}.part${chunkIndex}`);
      
//         // Simulating an API call
//         return new Promise((resolve) => {
//           setTimeout(() => {
//             console.log(`Uploaded chunk ${chunkIndex} of ${file.name}`);
//             resolve();
//           }, 500); // Simulated delay
//         });
//         // watchVideoApi() 
//         // .then((response) => {
//         //   if(response?.data?.http_code === 200){
           
//         //   }
//         // })
//         // .catch((error) => {
        
//         //  if(error) {
//         //   const errorObject = error?.response?.data;
//         //   showSnackBar(dispatch, errorObject);
        
//         // }
//         // });
//       }


//   return { uploadFile};
// }


import { useDispatch, useSelector } from "react-redux";
import { showSnackBar } from "../../utils/commonServices";
import { uploadChunkApi, uploadChunkPostFlightApi, uploadChunkPreflightApi, } from "../../services/api";
import { useState } from "react";

export default () => {
  const dispatch = useDispatch();
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [mediaId, setMediaId] = useState()

  const fileUploadPreflight = async (value, callback ) => {
  
    // uploadChunkPreflightApi(payload) 
    // .then((response) => {
    //   if(response?.data?.http_code === 200){
    //    console.log('preflight response', response?.data?.data?.id)
    //    setMediaId(response?.data?.data?.id)
    //   // callback(response?.data?.data?.id)
    //    return response?.data?.data?.id
    //  }
    // })
    // .catch((error) => {
    //  if(error) {
    //   const errorObject = error?.response?.data;
    //   showSnackBar(dispatch, errorObject);
    // }
    // });
    try {
      const response = await uploadChunkPreflightApi(value);
      if (response?.data?.http_code === 200) {
        console.log('preflight response', response?.data?.data?.id);
        return response?.data?.data?.id;  // This will now correctly return to the outer function
      } else {
        throw new Error('Unexpected response code');
      }
    } catch (error) {
      console.error('Error during preflight:', error?.response?.data || error.message);
      throw error;  // Ensure the calling function knows there was an error
    }
  }
  const fileUploadPostflight = async (value, callback) => {
    try {
      const response = await uploadChunkPostFlightApi(value);
      
      if (response?.data?.http_code === 200) {
        console.log('postflight response', response?.data);
        callback(0)
        // You can invoke the callback here if needed
        // callback(response?.data?.data?.id);
      }
    } catch (error) {
      callback(0)
      if (error) {
        console.error('Error:', error); // Corrected console.error for better logging
        // Handle specific error response if necessary
        // const errorObject = error?.response?.data;
      }
    }
  };

  const uploadFile = async (file, chunk, chunkIndex, fileIndex, payloadData) => {
    const formData = new FormData();
    formData.append('file', chunk, `${file.name}.part${chunkIndex}`);

    // // Simulating an API call with file_index as file_id
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     // Log with the required object format
    //     const logObject = {
    //       file_id: fileIndex,   // Use fileIndex as the file_id
    //       chunk_id: chunkIndex,
    //       file_name: file.name,
    //       chunk:chunk
    //     };
    //     console.log(`Uploaded chunk ${chunkIndex} of ${file.name}`, logObject);

    //     resolve();
    //   }, 500); // Simulated delay
    // });

    // Uncomment and adjust if using actual API
    const payload = {
      ...payloadData,
      media_file_id: mediaId
    }
    uploadChunkApi(payload)
      .then((response) => {
        console.log('api call')
        if (response?.data?.http_code === 200) {
          console.log("Success");
        }
      })
      .catch((error) => {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject);
      });
  };


 

  return { uploadFile, fileUploadPreflight , fileUploadPostflight};
};
