import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Drawer, IconButton, Input, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import CustomDrawerComponent from '../../../components/CustomDrawerComponent'
import { setGuestReviewState } from '../../../redux/guestSlice'
import luciLogo from "../../../assets/luciLogo2.png"
import CustomCard from '../../events/components/CustomCard'
import poweredBy from "../../../assets/poweredBy.svg"


const DrawerEventDetails = () => {

    const guestReducer = useSelector(state=> state.guestReducer)
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const themeReducer = useSelector((state) => state.root.themeReducer);
  const dispatch = useDispatch()
  const classes = useStyles();
  const navigate = useNavigate()
  const brandLogo = themeReducer?.brand_logo




  const closeDrawerHandler = ()=>{
    dispatch(setGuestReviewState({open_event_details_drawer: false}))
  }



  return (
    <CustomDrawerComponent
    openDrawer={guestReducer?.open_event_details_drawer} 
    onClose={closeDrawerHandler} 
    anchor='left' 
    // PaperProps={{ style: {borderRadius: '0px 10px 10px 0px' }}} 
    // sx={{backgroundColor:'transparent', borderRadius: '0px 10px 10px 0px' }}
    >
      <Box sx={{width:'80vw', height:'calc(100vh - 35px)', color:'#ffffff' }}  className={classes.container} >
                {/* <CustomCard sx={{height:"100%", width:'100%'}} customStyle={{borderRadius: '0px 10px 10px 0px'}} 
                    padding='5% 5% 5% 5%'> */}

              <Box sx={{width:'100%', }} className='flex j-between a-center' gap={6}>
                    {/* <Box sx={{width:{xs:'70px', md:'95px',lg:'120px',xl:'150px'}, mb: 2}}>
                            <img src={luciLogo} width= '100%' />
                    </Box> */}
                    <Box   className='flex col' sx={{maxWidth:'100px', minWidth:'80px'}}>
                    <img src={!!brandLogo ? brandLogo : luciLogo} style={{maxWidth:'70px', maxHeight:'35px'}} />
                    {
                      !!brandLogo && <Box textAlign='right' width='100%' mt={1}><img src={poweredBy}  width='60%'/></Box> 
                    }
                    </Box>
              </Box>

              
              <Box width="100%"   sx={{overflowY:'scroll', height:'85%'}} pl={2}>
                        {/* {
                        sortedEvents?.length > 0 ? sortedEvents?.map((event) => {
                          
                            return <Box className='flex row j-between a-center' sx={{mt: {xs:2, md:1.5,lg:2}}} >
                                <Typography onClick={() => onEventClick(event)}  sx={{  mr: 1, cursor: 'pointer', textTransform:'capitalize', wordBreak:'break-all', fontSize: {xs:'12px',sm:'14px',lg:'16px',xl:'22px'} }} >{event?.name}</Typography>
                                <DeleteIcon style={{fontSize: 13, marginRight: 12, cursor: 'pointer'}} onClick = {(e)=>deleteHandler(e, event)} />
                                </Box>
                        }) :
                        <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'><Typography sx={{fontSize: 30, color: '#fff', fontWeight: 800, textAlign: 'center', mb:1}} >Welcome to Lucihub‘s Assistant Creative Director!</Typography>
                    
                        <Typography sx={{fontSize: 30, color: '#fff', fontWeight: 300, textAlign: 'center', mt:3}} >Choose a content type to get started on your first project.</Typography></Box>
                    } */}
                           
                        </Box>

        {/* </CustomCard> */}

      </Box>
    </CustomDrawerComponent>
  )
}

export default DrawerEventDetails

const useStyles = makeStyles((theme) => ({
  container: {
      fontFamily: 'Rubik',
      height: '100%',
      minHeight: '100vh',
      overflowY: 'auto',
      // backgroundImage: 'radial-gradient(at top right,  #005998, #003877, #0f2a65, #141c53, #140f41, #140f41, #121633, #121633 , #121633)',
      borderRadius: '0px 10px 10px 0px',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display : 'none'
      },
  }
}));