import { useEffect, useRef, useState } from "react";
import Bottleneck from "bottleneck";
import { useDispatch, useSelector } from "react-redux";
import { base64ToBlob, isAcceptedFormat } from "../../../utils/commonServices";
import { getAllAssetsApi, getAllEventNotesApi, getAllEventSongsApi, guestUploadVideo, uploadVideo } from "../../../services/api";
//import useUpload from "../hooks/use";
import { setSnackbar } from "../../../redux/messageSlice";
import { uploadChunkApi } from "../../../services/api";
import useUploadActions from '../useUploadActions';
import { determineFileType} from '../commonServices';
import { fileFormat, removeExtension } from '../../../utils/commonServices';
import { uploadCategory } from "../../../constants/constants";
import { resetUploadState, setUploadState } from "../../../redux/uploadSlice";
import { updateProjectState } from "../../../redux/projectSlice";
import { setAssetState } from "../../../redux/assetSlice";
import useUploadedList from "../../../hooks/useUploadedList";
import { getAllEventNotes, getAllEventSongs } from "../../events/actions";
import { getAllAssets } from "../../brandbox/actions";

export default () => {
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [uploadedVideos, refetchList, uploadedListLoading] = useUploadedList(!!commonReducer.production_event_id);
  const [progress, setProgress] = useState([]);
  const [chunkProgress, setChunkProgress] = useState(0);
  const [requestController, setRequestController] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [imgSrc, setImgSrc] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([])
   const [uploadsInProgress, setUploadsInProgress] = useState(new Set());

  const [isLoading, setIsloading] = useState(false);

  const uploadReducer = useSelector((state) => state.root.uploadReducer);
  const dispatch = useDispatch();
  const maxSizeGb = 10;
  const { fileUploadPreflight, fileUploadPostflight } = useUploadActions();
  const filesRef = useRef([]); // Mutable reference to the files array
  const progressRef = useRef([])
  const controllerRef = useRef([]);
  const statusRef = useRef()

  // Sync the filesRef whenever the files array changes
  const syncFilesRef = (newFiles) => {
    filesRef.current = newFiles;
  };

  const syncProgressRef = (newArray) => {
    progressRef.current = newArray;
  };
  const syncControllerRef = (newArray) => {
    controllerRef.current = newArray;
  };

  const syncStatusRef = (newArray) => {
    statusRef.current = newArray;
  };

  //Callback to grab a function from child components to be executed here after api calls

 

  // Initialize Bottleneck for sequential chunk uploads
  const chunkLimiter = new Bottleneck({
    maxConcurrent: 1,
    minTime: 200, // Adjust based on your API rate limits
  });

  const fileLimiter = new Bottleneck({
    maxConcurrent: 1,
    minTime: 200, // Adjust based on your API rate limits
  });

  const getRefetchFunction = (category) => {
    // console.log('post flight refetch function.............................', category)
    switch (category) {
      case uploadCategory.assets:
          return getAllAssets(dispatch);
        
      case uploadCategory.media:
          return refetchList();
      
      case uploadCategory.notes:
          return  getAllEventNotes({event_id: commonReducer?.production_event_id} ,dispatch);
        
      case uploadCategory.songs:
          return   getAllEventSongs({event_id: commonReducer?.production_event_id} ,dispatch);
        
      default:
          return 'No action'; 
    }
  };

 // console.log(chunkProgress , 'chunk upload loop...............................................................')
  
 const updateStatus = (fileIndex, newStatus) => {
  const tempStatus = statusRef.current?.map((status_item, status_index)=> {
    if(status_index == fileIndex ) {
     return newStatus
    } else {
     return status_item;
    }
 })
 console.log("Temp status-------------------------------------------------->>>>>>",statusRef.current)
 dispatch(setUploadState({upload_status: tempStatus}));
 syncStatusRef(tempStatus)
 }

  // Function to simulate chunk upload
  const uploadChunk = async (media_id, chunkData, fileIndex, updateProgress, abortController) => {
    const payload = chunkData;
  console.log({abortController})
    try {
      const response = await uploadChunkApi({
        data: payload,
        progress: updateProgress,
        headers: { "Content-Type": "multipart/form-data" }, // Correct header format
       // controller: abortController.controller.signal
      });
     // console.log(`Chunk ${chunkIndex} uploaded successfully.`, response?.data);
     // 
      if (response?.data?.message === 'Chunk uploaded successfully') {
       // console.log(`Chunk ${chunkIndex} uploaded successfully.`, response?.data);
     //  setChunkProgress(0)
        return true;  // Indicate success
      } else {
        throw new Error(`Server responded with status`);
      }
  
    } catch (error) {
     // setChunkProgress(0);
      console.error(`Error uploading chunk ${chunkData.data.index}:`, error.message);
      return false;  // Indicate failure
    }
  };

  
  const uploadChunkWithRetry = async (fileId, chunk, fileIndex, updateProgress, abortController, maxRetries = 5, delay = 1000) => {
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        // Attempt to upload the chunk
        const response = await uploadChunk(fileId, chunk, fileIndex, updateProgress, abortController);
       
        // Check for a successful response
        if (response) {
          console.log(`Chunk ${chunk.data.index} uploaded successfully on attempt ${attempt}.`);
          return true;  // Exit function on success
        } else {
          throw new Error(`Server responded with an error on attempt ${attempt}`);
        }
  
      } catch (error) {
        console.error(`Error uploading chunk ${chunk.data.index}, attempt ${attempt}:`, error.message);
  
        // Wait before retrying
        if (attempt < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, delay * attempt));  // Exponential backoff
        } else {
          console.error(`Chunk ${chunk.data.index} failed after ${maxRetries} attempts.`);
          updateStatus(fileIndex, { type: "error", message: "Upload Failed!!" })
          return false;  // Final failure after retries
        }
      }
    }
  };
  
  
  // Function to handle file processing
  const processFileUpload = async (file, fileIndex) => {

    const chunkSize = 1024 * 1024 * 10; // Example: 10MB chunk size
    const totalChunks = Math.ceil(file.size / chunkSize);
     const fileType = determineFileType(file)
     const preFlightPayload = {
     data: {
      name: file?.name,
      content_type: fileType,
      size: file?.size,
      total_chunk_count: totalChunks,
      ...file.extraPayload
    },
      category: file.category
    }
   const media_id = await fileUploadPreflight(preFlightPayload) // First API call to send file details
  if(!!media_id) {
   // let tempChunksArray = []
   let successCounter = 0
   let tempFileProgress = 0
   //setChunkProgress(0)
    for (let i = 1; i <= totalChunks; i++) {
     const updateFileProgress = (tempProgress)=> {
      tempFileProgress = parseFloat((i-1)/totalChunks)*100.0 + parseFloat(tempProgress/totalChunks)
     // console.log(tempFileProgress, 'tempfileprogress......................')
      // setProgress((last) => {
      //   var temp = last?.map((num, index) => {
      //     if (fileIndex == index) {
      //       return tempFileProgress;
      //     } else {
      //       return num;
      //     }
      //   });
      //   return temp;
      // });

      const temp = progressRef.current?.map((num, index) => {
            if (fileIndex == index) {
              return tempFileProgress;
            } else {
              return num;
            }
          });
      dispatch(setUploadState({progress: temp}))
     }
     // console.log(tempFileProgress, 'tempfileprogress......................')
  // console.log(chunkProgress , totalChunks, 'chunk upload loop...............................................................')
       
    //  console.log(tempFileProgress, 'tempfileprogress......................')
      //dispatch(setUploadState({file_progress: tempFileProgress}))
     
      const start = (i-1) * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunk = file.slice(start, end);
      // const payloadData = {
      //   file:chunk,
      //   index: i,
      //   filename: `${removeExtension(file?.name)}_${i}`,
      //   size:chunk?.size
      // }
      const payloadData = {
        data: {
          file:chunk,
          index: i,
          filename: `${removeExtension(file?.name)}_${i}`,
          size:chunk?.size,
          id: media_id,
       },
       category: file.category
      }

      const abortController = controllerRef.current[fileIndex]
      console.log("abort controller...................>>>>>>>>>>>",controllerRef.current)
     //  tempChunksArray.push(payloadData);
     // Schedule each chunk upload with the limiter
     const success = await chunkLimiter.schedule(() =>
     uploadChunkWithRetry(media_id, payloadData, fileIndex, updateFileProgress, abortController)
   );

   // If a chunk ultimately fails, stop the process
   if (success){
      successCounter++
   } else {
     throw new Error(`Failed to upload chunk ${i}, stopping file upload.`);
   }
    }
     // After all chunks are uploaded, trigger integration
     if(successCounter == totalChunks) {
      const payload = {
        data: {
          id: media_id
        },
        category: file.category
      }
      const postFlightCallback = (progress)=> {
        setChunkProgress(progress)
        // refetchList()
        getRefetchFunction(file.category)
        updateStatus(fileIndex, { type: "success", message: "Uploaded Successfully!" })
      }
      await fileUploadPostflight(payload, postFlightCallback)

      if(fileIndex == uploadsInProgress?.length) {
       
      }
      // setUploadsInProgress((prev) => {
      //   const newProgress = new Set(prev);
      //   newProgress.delete(file.name);  // Remove from in-progress set
      //   return newProgress;
      // });
    } else {
      updateStatus(fileIndex, { type: "error", message: "Upload Failed!" })
      console.log("Few parts of file are missing. try to re upload")
    }
  }
   
   // await finalizeFileUpload(fileId);
  };
  
  // Main function to handle multiple files
  const uploadFiles = async () => {
   
    for (let index = 0; index < filesRef.current.length; index++) {
      const file = filesRef.current[index];
      let tempSet;
      setUploadsInProgress((prev) => {
        tempSet = new Set(prev).add(file.name);
       return tempSet;
      });
      dispatch(setUploadState({is_loading: true, in_progress_files: tempSet}))
      try {
        if (!uploadsInProgress.has(file.name)) {
          const tempTotalProgress = ((index/filesRef.current?.length)*100.0) + (uploadReducer.file_progress/filesRef.current?.length)
          dispatch(setUploadState({total_progress: tempTotalProgress}))
          await processFileUpload(file, index);

        }
        
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }
    }
    setTimeout(()=> {
      dispatch(resetUploadState())
    }, 1500)
   
  };
  
  // Usage in a React component
  const handleFileUpload = (event) => {
   // const files = event.target.files;
   // uploadFiles(files);
  };
  


  // const refresh = async (index) => {
  //   if (index == files?.length - 1 && !isGuestUser) {
  //     setTimeout(() => {
  //        refetch();
  //        setFiles([])
  //     }, 3000);
  //   }
  // };

  // const stopUpload = (index) => {
  //   requestController[index].abort();
  //   setUploadStatus((last) => {
  //     var temp = last?.map((num, i) => {
  //       if (i == index) {
  //         return { type: "error", message: "Upload Cancelled!" };
  //       } else {
  //         return num;
  //       }
  //     });
  //     return temp;
  //   });
  // };

  // const stopList = () => {
  

  //   setUploadStatus((last) => {
  //     var temp = last?.map((num, i) => {
  //       if(num.type == "standard" ) {
  //         requestController[i].abort();
  //         return { type: "error", message: "Upload Cancelled!" };
  //       } else {
  //         return num;
  //       }
        
  //     });
  //     return temp;
  //   });

  //   // setTimeout(() => {
  //   //   window.location.reload();
  //   // }, 1000);
  // };
  useEffect(()=> {
    syncProgressRef(progress)
  },[progress])

  useEffect(()=> {
    syncStatusRef(uploadStatus)
  }, [uploadStatus])

  // useEffect(()=> {
  //   syncControllerRef(requestController)
  // }, [requestController])

  // useEffect(()=> {

  //   const templController = controllerRef.current?.map((controller, i)=> {
     
  //     return {...controller, trigger: uploadReducer.abort_controller[i].trigger}
  //   })
  //   console.log({templController})
  //   syncControllerRef(templController)
  // }, [ uploadReducer.abort_controller])

  // useEffect(()=> {
  //   controllerRef.current.map((item, i)=> {
  //     if(item.trigger) {
  //       console.log("aborted")
  //       item.controller.abort()
  //     }
       
  //   })
 // },[controllerRef.current])

  useEffect(() => {
    
    syncFilesRef(selectedFiles); // Sync the reference
    if(selectedFiles?.length > 0) {
      dispatch(setUploadState({ upload_list_drawer: true }))
    //   dispatch(updateProjectState({upload_documents_to_editor_popup : false, upload_song_popup : false})) 
    // dispatch(setAssetState({upload_files_popup : false})) 
    } 
    
    if(!uploadReducer.is_loading) {
      uploadFiles()
    }
   //uploadFiles()
  }, [selectedFiles]);

  // const uploadVideoService = (fData, index, controller) => {
  //   setIsloading(true);
    
  //   return new Promise((resolve, reject) => {
  //     uploadChunkApi({
  //       data: fData,
  //       // progress: (number) => {
  //       //   setProgress((last) => {
  //       //     var temp = last?.map((num, i) => {
  //       //       if (i == index) {
  //       //         return number;
  //       //       } else {
  //       //         return num;
  //       //       }
  //       //     });
  //       //     return temp;
  //       //   });

  //       //   setUploadStatus((last) => {
  //       //     var temp = last?.map((num, i) => {
  //       //       if (i == index) {
  //       //         if (number > 99) {
  //       //           return { type: "standard", message: "Finalizing..." };
  //       //         } else {
  //       //           return { type: "standard", message: "Uploading..." };
  //       //         }
  //       //       } else {
  //       //         return num;
  //       //       }
  //       //     });
  //       //     return temp;
  //       //   });
  //       // },
  //       header: { "content-type": "multipart/form-data" },
  //      // controller: controller,
  //     })
  //       .then((r) => resolve(r.data))
  //       .catch((e) => reject(e));
  //   });
  
  // };

  return {
    files,
    setFiles,
    selectedFiles, setSelectedFiles,
    uploadsInProgress, setUploadsInProgress,
    uploadFiles,
    uploadStatus,
    setUploadStatus,
    progress,
    setProgress,
    imgSrc,
    setImgSrc,
    isLoading,
    requestController,
    setRequestController,
  //  stopUpload,
   // stopList,
  };
}
