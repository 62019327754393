import { configureStore } from '@reduxjs/toolkit'
import commonSlice from './commonSlice'
import messageSlice from "./messageSlice"
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import dropdownSlice from './dropdownSlice'
import  eventCreationSlice  from './eventCreationSlice'
import reviewSlice from './reviewSlice'
import { combineReducers } from '@reduxjs/toolkit/dist'
import persistCombineReducers from 'redux-persist/es/persistCombineReducers'
import mediaFilesSlice from './mediaFilesSlice'
import appleSignSlice from './appleSignSlice'
import teamSlice from './teamSlice'
import projectSlice from './projectSlice'
import permissionSlice from './permissionSlice'
import customThemeSlice from './customThemeSlice'
import assetSlice from './assetSlice'
import guestSlice from './guestSlice'
import pendingCallSlice from './pendingCallSlice'
import uploadSlice from './uploadSlice'

const persistConfig = { key: 'root', version: 1, storage }

const persistedReducer = persistCombineReducers(persistConfig, {
   commonReducer: commonSlice,
   dropdownReducer: dropdownSlice,
   teamsReducer: teamSlice,
   themeReducer : customThemeSlice,
   uploadReducer: uploadSlice,
  })

 

export const store = configureStore({
    reducer: {
        root: persistedReducer,
       // commonReducer: persistedReducer,
        messageReducer: messageSlice,
        //dropdownReducer: dropdownSlice,
        createEventReducer: eventCreationSlice,
        reviewEventReducer: reviewSlice,
        mediaFilesReducer: mediaFilesSlice,
        appleSignReducer: appleSignSlice,
        permissionsReducer: permissionSlice,
        pendingCallReducer: pendingCallSlice,
        projectReducer: projectSlice,
        assetReducer: assetSlice,
        guestReducer: guestSlice
        
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})