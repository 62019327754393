// // import React, { useState } from 'react';
// // import useChunkedUpload from './useChunkUpload';

// // // Example API function to handle file chunk uploads
// // const uploadFile = async (file, chunk, chunkIndex) => {
// //   // API call to upload the file chunk (replace with your API logic)
// //   const formData = new FormData();
// //   formData.append('file', chunk, `${file.name}.part${chunkIndex}`);

// //   // Simulating an API call
// //   return new Promise((resolve) => {
// //     setTimeout(() => {
// //       console.log(`Uploaded chunk ${chunkIndex} of ${file.name}`);
// //       resolve();
// //     }, 500); // Simulated delay
// //   });
// // };

// // const FileUploadComponent = () => {
// //   const { handleUpload, uploadProgress, overallProgress, isUploading, uploadComplete } = useChunkedUpload(uploadFile, 1024 * 1024); // 1MB chunk size
// //   const [files, setFiles] = useState([]);

// //   const handleFileChange = (event) => {
// //     setFiles(event.target.files);
// //     console.log('handle submit')
// //     handleUpload(event.target.files);
// //   };

// //   const handleSubmit = () => {
// //     console.log('handle submit')
// //     handleUpload(files);
// //   };

// //   return (
// //     <div>
// //       <input type="file" multiple onChange={handleFileChange} />
// //       <button onClick={()=>setFiles([])} disabled={isUploading} >
// //         {isUploading ? 'Uploading...' : 'clear array'}
// //       </button>

// //       <h3>Overall Progress: {overallProgress}%</h3>

// //       {Object.keys(uploadProgress).map((fileName) => (
// //         <div key={fileName}>
// //           {fileName}: {uploadProgress[fileName]}%
// //         </div>
// //       ))}

// //       {uploadComplete && <p>All files uploaded successfully!</p>}
// //     </div>
// //   );
// // };

// // export default FileUploadComponent;


// import React, { useState, useRef } from 'react';
// import useChunkedUpload from './useChunkUpload';
// import useUploadActions from './useUploadActions'; // Import your upload actions hook

// const FileUploadComponent = () => {
//   const { uploadFile } = useUploadActions();
//   const { handleUpload, uploadProgress, overallProgress, isUploading, uploadComplete } = useChunkedUpload(uploadFile, 1024 * 1024); // 1MB chunk size
//   const [files, setFiles] = useState([]);
//   const fileInputRef = useRef(null);

//   const handleFileChange = (event) => {
//     setFiles(event.target.files);
//     handleUpload(event.target.files); // Initiate upload
//   };

//   const handleClear = () => {
//     setFiles([]);
//     if (fileInputRef.current) {
//       fileInputRef.current.value = '';
//     }
//   };

//   return (
//     <div>
//       <input type="file" multiple onChange={handleFileChange} ref={fileInputRef} />
//       <button onClick={handleClear} disabled={isUploading}>
//         {isUploading ? 'Uploading...' : 'Clear Files'}
//       </button>

//       <h3>Overall Progress: {overallProgress}%</h3>

//       {Object.keys(uploadProgress).map((fileName) => (
//         <div key={fileName}>
//           {fileName}: {uploadProgress[fileName]}%
//         </div>
//       ))}

//       {uploadComplete && <p>All files uploaded successfully!</p>}
//     </div>
//   );
// };

// export default FileUploadComponent;



import React, { useState, useRef, useEffect } from 'react';
import useChunkedUpload from './useChunkUpload';
import useUploadActions from './useUploadActions'; // Import your upload actions hook
import UploadDrawer from './components/UploadDrawer';
import { determineFileType } from './commonServices';
import { fileFormat, removeExtension } from '../../utils/commonServices';
import Bottleneck from 'bottleneck';
import { uploadChunkApi } from '../../services/api';

const FileUploadComponent = () => {
  const { uploadFile, fileUploadPreflight, fileUploadPostflight } = useUploadActions();
  const chunkSize = 1024 * 1024 * 10
  const { handleUpload, uploadProgress, overallProgress, isUploading, uploadComplete, uploadedChunks } = useChunkedUpload( 1024 * 1024); // 1MB chunk size
  const [files, setFiles] = useState([]);
  const [chunks, setChunks] = useState([])
  const [chunkCounter, setChunkCounter] = useState(0);
  const [mediaId, setMediaId] = useState()
  const fileInputRef = useRef(null);

  // const handleFileChange = (event) => {
  //   setFiles(event.target.files);
  //   handleUpload(event.target.files); // Initiate upload
  // };

  const handleClear = () => {
    setFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = async (event) => {
    // const selectedFiles = Array.from(event.target.files);
    // setFiles(selectedFiles);
    const selectedFile = event.target.files[0]

    let tempChunksArray = []
    let fileType, preFlightPayload;
    let counter = 0;
    
        const totalChunks = Math.ceil(selectedFile.size / chunkSize);


        for (let chunkIndex = 1; chunkIndex <= totalChunks; chunkIndex++) {
          const start = chunkIndex * chunkSize;
          const end = Math.min(start + chunkSize, selectedFile.size);
          const chunk = selectedFile.slice(start, end);

         const payloadData = {
          file:chunk,
          index: chunkIndex,
          filename: `${removeExtension(selectedFile?.name)}_${chunkIndex}}`,
          size:chunk?.size

         }

         tempChunksArray.push(payloadData);

        // 1. Preflight request to get mediaId
          fileType = determineFileType(selectedFile)
          preFlightPayload = {
          name: selectedFile?.name,
          content_type: fileType,
          size: selectedFile?.size,
          total_chunk_count: totalChunks,
         }

        
     // const mediaId = await fileUploadPreflight(preFlightPayload);
      // if (!mediaId) {
      //   console.error(`Failed to get mediaId for file ${selectedFile.name}`);
      //   return;
      // } else {
      //   console.log({mediaId})
      // }

        }

      setChunks(tempChunksArray)
      const startUploadingChunks = async (media_id)=> {
        setMediaId(media_id)
        const limiter = new Bottleneck({
          maxConcurrent: 1,
          minTime: 200,
        });
        // if(files?.length > 0 && imgSrc?.length > 0 && files?.length == imgSrc?.length && progress?.length == files?.length) {
        if (
          tempChunksArray?.length > 0 
        ) {
          const uploadRequest = async () => {
            var tempControllers = [];

            for (let index = 0; index < tempChunksArray?.length; index++) {
              //if (!isAcceptedFormat(files[index].name)) {
              //   setIsloading(false);
              //   setUploadStatus((last) => {
              //     var temp = last?.map((status, i) => {
              //       if (i == index) {
              //         return { type: "error", message: "Invalid format" };
              //       } else {
              //         return status;
              //       }
              //     });
              //     return temp;
              //   });
    
              //   // refresh(index)
              // } else 
    
                //  uploadVideoService({"id": eventId, "videos": files[index], "thumbs": thumb}, index)
    
                limiter
                  .schedule(() => {
                    const payload = {
                      ...tempChunksArray[index],
                      media_file_id: media_id
                    }
                    uploadChunkApi({
                      data: payload,
                      header: { "content-type": "multipart/form-data" },
                    })
                      .then((response) => {
                        console.log('api call')
                        if (response?.data?.http_code === 200) {
                          counter = counter + 1;
                          console.log("Success");
                        }
                      })
                      .catch((error) => {
                        const errorObject = error?.response?.data;
                       /// showSnackBar(dispatch, errorObject);
                      });
                  }
                  )
                  .then((result) => {
                    // setIsloading(false);
                    // setUploadStatus((last) => {
                    //   var temp = last?.map((num, i) => {
                    //     if (i == index) {
                    //       return {
                    //         type: "success",
                    //         message: "Uploaded Successfully!",
                    //       };
                    //     } else {
                    //       return num;
                    //     }
                    //   });
                    //   return temp;
                    // });
    
                    //  refresh(index)
                   
                  
                  
                  })
                  .catch((err) => {
                    
                    // setIsloading(false);
                    // setUploadStatus((last) => {
                    //   var temp = last?.map((num, i) => {
                    //     if (i == index) {
                    //       if (
                    //         err?.response?.data?.error == "File already present"
                    //       ) {
                    //         return {
                    //           type: "error",
                    //           message: err?.response?.data?.error,
                    //         };
                    //       } else if (err?.message == "canceled") {
                    //         return { type: "error", message: "Upload Cancelled!" };
                    //       } else {
                    //         if(err?.response?.data?.http_code === 403) {
                    //           dispatch(setSnackbar({type:'error', flag:true, message: err?.response?.data?.message}))
                    //         }
                    //         return { type: "error", message: "Upload Failed!" };
                    //       }
                    //     } else {
                    //       return num;
                    //     }
                    //   });
                    //   return temp;
                    // });
                    //  refresh(index)
                    console.log({err})
                  });
              
            }

            setChunkCounter(counter)
            console.log({counter})
          };
      
          console.log({tempChunksArray})
         await uploadRequest();
        
        }
    
        //return () => {
         // limiter.stop().then(() => {
            //console.log("Shutdown completed!");
        //  });
       // };
       }

      const id = await fileUploadPreflight(preFlightPayload);
      console.log({id})
       
  
    // for (const file of selectedFiles) {
    //   const totalChunks = Math.ceil(file.size / chunkSize);
    //   const fileType = determineFileType(file);
      
    //   const payloadData = {
    //     name: file.name,
    //     content_type: fileType,
    //     size: file.size,
    //     total_chunk_count: totalChunks,
    //   };
  
    //   try {
    //     const mediaId = await fileUploadPreflight(payloadData); // Get mediaId from preflight
    //     await handleUpload(file, mediaId); // Start chunked upload for each file with its mediaId
    //   } catch (error) {
    //     console.error(`Error in preflight for file ${file.name}`, error);
    //   }
    // }
  };

// useEffect(()=> {
//   console.log("post data", chunkCounter, chunks?.length, mediaId)
//     if(chunkCounter == chunks?.length && !!mediaId ) {
//      fileUploadPostflight({media_file_id: mediaId})
//     }
// }, [chunkCounter, mediaId, chunks])

const sendPostflight = async ()=> {
 await fileUploadPostflight({media_file_id: mediaId})
}

  const combineAndDownload = () => {
    if (files.length === 0) {
      console.log("No files to combine.");
      return;
    }

    const firstFile = files[0];
    const chunks = uploadedChunks[firstFile.name]; // Get the chunks for the first file

    if (!chunks || chunks.length === 0) {
      console.log("No chunks available for the first file.");
      return;
    }

    const combinedBlob = new Blob(chunks);
   
    const url = URL.createObjectURL(combinedBlob);
    
  
    const a = document.createElement('a');
    a.href = url;
    a.download = firstFile.name; 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    console.log(`Combined file ${firstFile.name} created with ${chunks.length} chunks. ${firstFile}`);
  };
  console.log(uploadProgress)
  return (
    <div>
      <input type="file" multiple onChange={handleFileChange} ref={fileInputRef} />
      <button onClick={handleClear} disabled={isUploading}>
        {isUploading ? 'Uploading...' : 'Clear Files'}
      </button>
      <button onClick={sendPostflight} >
       Send PostFlight
      </button>

      <h3>Overall Progress: {overallProgress}%</h3>

      {/* {Object.keys(uploadProgress).map((fileName) => (
        <div key={fileName}>
          {fileName}: {uploadProgress[fileName]}%
        </div>
      ))} */}

      {uploadComplete && <p>All files uploaded successfully!</p>}
    {   <UploadDrawer uploadProgress={uploadProgress} isUploading={isUploading} overallProgress={overallProgress}/>}
    </div>
  );
};

export default FileUploadComponent;
