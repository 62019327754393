export function determineFileType(file) {
    let fileType = "";
  
    if (!file || !file.type) {
      return fileType; // Return empty if no file is provided
    }
  
    const mimeType = file.type;
  
    if (mimeType.startsWith("video/")) {
      fileType = "video";
    } else if (mimeType.startsWith("audio/")) {
      fileType = "audio";
    } else if (mimeType.startsWith("image/")) {
      fileType = "image";
    }
  
    return fileType;
  }