import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "@fontsource/rubik";
import { getInitials } from '../../../utils/commonServices';
import useCustomTheme from '../../../theme/useCustomTheme';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { updateReviewState } from '../../../redux/reviewSlice';
import { setGuestReviewState } from '../../../redux/guestSlice';
import CustomTooltip from '../../../components/CustomTooltip';
import useGuestReviewActions from '../useGuestReviewActions';
import DeleteIcon from '@mui/icons-material/Delete';

const RevisionLogNotes = (props) => {
    const {message, userName, timestamp, color, setText, log} = props;
   const commonReducer = useSelector((state) => state.root.commonReducer);
   const guestReducer = useSelector(state=> state.guestReducer)
   const [theme ] = useCustomTheme()
   const dispatch = useDispatch()
   const {deleteGuestLog} = useGuestReviewActions()

   const editLogHandler =()=>{
    setText(message)
    dispatch(updateReviewState({timestamp: timestamp}))
    dispatch(setGuestReviewState({log_id: log?.id, edit_flag: true}))
   }

   const deleteLogHandler =()=>{
    dispatch(setGuestReviewState({log: log, delete_log_confirmation_popup: true}))
    // deleteGuestLog({id: log?.id , param:{guest_reviewer_id: guestReducer?.guest_reviewer_id}})
   }


  return (
    <Box className='flex' gap={2} sx={{justifyContent:'space-between', alignItems:'start'}}>
        <Box className='flex a-start j-start' gap={2}>
          <CustomTooltip title={userName}  placement="right-end" sx={{ '& .MuiTooltip-tooltip': {
           backgroundColor: log?.deleted ? '#acacac' : commonReducer?.user_name == userName ? theme.palette.primary.main: color,
          color: '#ffffff !important',    
          opacity:0.8       // Change text color if needed
        },}}>
             <Avatar sx={{borderRadius:'5px', backgroundColor: log?.deleted ? '#acacac' : commonReducer?.user_name == userName ? theme.palette.primary.main: color, color:'white',width:{xs:'18px',sm:'20px' ,md:'22px' , lg:'23.5px' ,xl:'35px'}, height:{xs:'18px',sm:'20px' ,md:'22px' , lg:'23.5px' ,xl:'35px'}, paddingRight:0, marginRight:0,  fontSize:{xs:'7px',md:'8px',lg:'9px',xl:'12px'}, textTransform:'uppercase', fontFamily:'Rubik'}}>{getInitials(userName)}</Avatar>
          </CustomTooltip>
          <Box>
            <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'10px',xl:'13px'},  fontWeight:'normal', fontFamily:'Rubik',    color:'#08231B', textAlign: 'justify' , textDecoration: log?.deleted && 'line-through', wordBreak:'break-all'}}>
            {message}
            </Typography>
          
            <Typography  sx={{fontSize:{xs:'7px',md:'9px',lg:'9px',xl:'12px'},  fontWeight:'normal', fontFamily:'Rubik',    color:'#08231B', textAlign: 'justify', fontWeight:600 , fontStyle:'italic', mt:0.3}}>
            {timestamp}
            </Typography>
          </Box>
        </Box>

          
          <Box className='flex a-center' gap={1}>  
            {log?.deleted === false &&   
              <CustomTooltip title="Edit log">
               <EditOutlinedIcon onClick={editLogHandler}  sx={{fontSize:{xs:'10px',lg:'12px', xl:'16px'}, cursor:'pointer'}}/>
              </CustomTooltip>
               }

               {log?.deleted === false &&  
                <CustomTooltip title="Delete log"> 
                  <DeleteIcon onClick={deleteLogHandler}  sx={{fontSize:{xs:'10px',lg:'12px', xl:'16px'}, cursor:'pointer'}}/> 
                  </CustomTooltip>
                }
          </Box>
    </Box>
  )
}

export default RevisionLogNotes