import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import File from '../../../assets/file.svg'
import "@fontsource/rubik";
import useCustomTheme from '../../../theme/useCustomTheme';
import useCustomDropzone from '../../../hooks/useCustomDropzone';


const BrandboxUploadZone = (props) => {

    const [theme] = useCustomTheme()

    const {
        height,
        //width,
        setUploadStatus,
        isDisabled = false,
    } = props;

    const { getInputProps, getRootProps, style, open } = useCustomDropzone(props)
    const browseFilesClickHandler = ()=> {
        open();
    }
console.log("open", browseFilesClickHandler)

useEffect(() => {
    console.log("Popup mounted, open:", open);
}, [open]);
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

            <Grid className="container"
                style={{ display: 'flex' }}
                sx={{
                    // display: { xs: 'none', md: 'inline' },
                    height: height ? height : { xs: '60vh', md: '55vh', lg: '55vh', xl: '56vh' },
                    width: '100%'
                }}>

                <Grid  {...getRootProps({ style, className: 'dropzone' })} >
                    <input {...getInputProps()} />
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }} >

                            <Box sx={{ width: { xs: '25px', md: '30px', lg: '50px', xl: '74px' }, height: { xs: '20px', md: '25px', lg: '38px', xl: '50px' }, color: '#878887', marginBottom: { xs: '5px', md: '5px', lg: '5px', xl: '15px' } }} >
                                <img src={File} height='100%' width='100%' />
                            </Box>


                            <Typography sx={{ fontSize: { xs: '10px', sm: '10px', md: '12px', lg: '16.67px', xl: '25px' }, fontWeight: 600, margin: 0, padding: 0, textAlign: 'center', letterSpacing: 1.25, color: '#878887', fontFamily: "Rubik" }}>Drag & Drop existing files here</Typography>
                        </Box>

                        <Button color='secondary' variant='contained' disabled={isDisabled} onClick={browseFilesClickHandler} style={{ fontFamily: "Rubik", backgroundColor: isDisabled == true ? "#52636C50" : theme.palette.secondary.main, borderRadius: '5px', border: 'none', cursor: 'pointer', fontWeight: 600, textTransform: 'capitalize', minWidth: '76px' }} sx={{
                            fontSize: { xs: '7px', sm: '8px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, mt: '5px',
                            // width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},
                            paddingY: { xs: 0.2, lg: 0.4 }
                        }}>
                            Or Browse
                        </Button>

                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
}

export default BrandboxUploadZone;