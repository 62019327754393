
import "@fontsource/rubik";
import { Backdrop, Box, Typography} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomUserCard from "../events/components/CustomUserCard";
import CustomSnackbar from "../../components/CustomSnackbar";
import { useSelector } from "react-redux";
import DeleteAccountConfirmationPopup from "../dashboard/components/DeleteAccountConfirmationPopup";
import useDeleteUser from "../dashboard/hooks/useDeleteUser";
import CustomSnackbarDrawer from "../../components/CustomSnackbarDrawer";
import CreateProjectSpinner from "../eventcreation/components/CreateProjectSpinner";
// import { customTheme as theme } from "../../theme/customTheme";
import SelectThemePopup from "../whitelabel/components/SelectThemePopup";
import CompanyNamePopup from "../brandbox/components/CompanyNamePopup";
import useCustomTheme from "../../theme/useCustomTheme";
import { useEffect } from "react";
import UploadDrawer from "../upload/components/UploadDrawer";

const useStyles = makeStyles((theme) => ({
    container: {
       // position: 'absolute',
       // padding: '4.5% 4.9% 6% 5.9%',
        fontFamily: 'Rubik',
        height: '100%',
        minHeight: '100vh',
        // overflowY: 'auto',
       // width: '100%',
      //  minWidth: '860px',
        // backgroundImage: 'linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
        //below gradient to test 



        background: `linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670, ${theme.palette.secondary.main}20 , ${theme.palette.secondary.main}50 80%,  ${theme.palette.primary.main}60 , ${theme.palette.primary.main}60 )`,



        // backgroundImage: `linear-gradient(to right bottom, #ffffff, #ffffff, #efefef, #efefef, ${theme.palette.primary.main}10, ${theme.palette.primary.main}20, ${theme.palette.primary.main}30, ${theme.palette.primary.main}60, ${theme.palette.primary.main} )`,
        // backgroundImage: `linear-gradient(to right bottom, #ffffff, #ffffff, #efefef, #efefef, ${primaryColor ? primaryColor : theme.palette.primary.main}10, ${primaryColor ? primaryColor : theme.palette.primary.main}20, ${primaryColor ? primaryColor : theme.palette.primary.main}30, ${primaryColor ? primaryColor : theme.palette.primary.main}60, ${primaryColor ? primaryColor : theme.palette.primary.main} ) !important`,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll !important',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display : 'none'
        },
    }
}));

const AuthLayout = (props) => {

    const classes = useStyles();
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const uploadReducer = useSelector((state) => state.root.uploadReducer);
    const themeReducer = useSelector((state) => state.root.themeReducer);
    const primaryColor = themeReducer?.primary
    const assetReducer = useSelector((state) => state.assetReducer);
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const snackbarProps = useSelector((state) => state.messageReducer);
    const [deleteUserMutate, deleteUserStatus] = useDeleteUser()
    const [theme] = useCustomTheme()



    const deleteUserHandler =(id)=>{
      deleteUserMutate({id})
    }

    
    return <Box {...props}  className={classes.container}  >
             {props.children}

            {createEventReducer.create_project_backdrop_loader &&  <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(15px) !important', display:'flex', flexDirection:'column',  background: themeReducer?.primary ? `${theme.palette.primary.main}30 0% 0% no-repeat padding-box` : '#08231B20 0% 0% no-repeat padding-box' , }}
                open={createEventReducer.create_project_backdrop_loader}
                >
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(23px) !important', display:'flex', flexDirection:'column', background: '#08231B99 0% 0% no-repeat padding-box', }}
                open={createEventReducer.create_project_backdrop_loader}
                >
                  <Box className='flex col' sx={{justifyContent:'center', alignItems:'center'}}>
                    <CreateProjectSpinner/>
                    <Typography sx={{fontSize:'50px', color:'white', fontWeight:600, fontFamily:'Rubik'}}>Creating your project…</Typography>
                    <Typography sx={{fontSize:'20px', color:'white', fontFamily:'Rubik'}}>Please wait as the fun is just getting started.</Typography>
                  </Box>
              </Backdrop>
            </Backdrop>}
            
          {Object.keys(snackbarProps).length > 0 && (
          <CustomSnackbar
         
          />
        )}
        {commonReducer.account_delete_popup == true && <DeleteAccountConfirmationPopup deleteUserHandler={deleteUserHandler} status={deleteUserStatus}/>}
        {commonReducer?.change_theme_popup && <SelectThemePopup/>}
        { assetReducer.brand_name_popup && <CompanyNamePopup/>}
        {  uploadReducer.upload_list_drawer && <UploadDrawer uploadProps = {props.uploadProps}/>}
           </Box>  


}

export default AuthLayout