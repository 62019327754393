import React, { useEffect } from 'react'
import AuthLayout from '../layouts/AuthLayout'
import { AppBar, Box, IconButton, Typography,Input,  Backdrop} from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from '../../components/CustomButton';
import { useState } from 'react';
import CustomSortOptions from '../events/components/CustomSortOptions';
import { timeStampToString } from '../../utils/commonServices';
import { useDispatch, useSelector } from 'react-redux';
import useSort from '../events/hooks/useSort';
import { useNavigate } from 'react-router';
import Header from '../userfiles/components/Header';
import CustomAutocomplete from '../eventcreation/components/CustomAutoComplete';
import { BRANDBOX_OPTIONS } from '../../constants/staticData';
import BrandboxOptions from './components/BrandboxOptions';
import { getNamesFromIds } from '../../commonactions/actions';
import { getErrorMsg } from '../../utils/validator';
import { createAsset, getAllAssets, getAssetOptionsList } from './actions';
import CompanyNamePopup from './components/CompanyNamePopup';
import { setAssetState } from '../../redux/assetSlice';
import UploadFilesPopup from './components/UploadFilesPopup';
import { checkTokenSession } from '../dashboard/actions';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import MobileTable from './components/MobileTable';
import useMediaQuery from '@mui/material/useMediaQuery';


 const UserBrandbox = (props) => {

    const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const assetReducer = useSelector((state) => state.assetReducer);
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobileView = useMediaQuery('(max-width:800px)');
    
    
    const [assets, setAssets] = useState(assetReducer?.selected_asset_files)
    const [searchedArray, setSearchedArray] = useState(assets)
    const [sortedFiles] = useSort(searchedArray, selectedSortbyValue)
    const [selectedBrandboxOption, setSelectedBrandboxOption] = useState({option_id: ''})
    const [brandboxOptionValidationFlag, setBrandBoxOptionValidationFlag] = useState(false)
    const [acceptType, setAcceptType] = useState('')
    const [expanded, setExpanded] = useState('');
    const [errorText, setErrorText] = useState('')





      const handleSearch = (e) => {
        const searchedInput = e.target.value.toLowerCase();
        setSearchTerm(searchedInput);

        const updatedFiles = [...assets];
   
        const results = updatedFiles?.filter(item =>
          item?.filename?.toLowerCase().includes(searchedInput) ||
          item?.event_name?.toLowerCase().includes(searchedInput)||
          item?.created_by?.toLowerCase().includes(searchedInput)||
          timeStampToString(item?.created_at)?.toLowerCase().includes(searchedInput)
        );
        setSearchedArray(results);
      };


      const isFileAlreadyExists = (fileName) => {
        const label = getNamesFromIds(selectedBrandboxOption?.option_id, dropdownReducer.asset_options)?.name
 
        const files = assetReducer?.all_assets?.find( option => option.label == label)?.files

        return files?.some((existingFile) => existingFile.filename === fileName);
      };

     const uploadFileHandler =(event, newValue)=>{

      if (isFileAlreadyExists(event.target.files[0]?.name)) {
        // Display an error message or take appropriate action
        setBrandBoxOptionValidationFlag(true)
        setErrorText('File with the same name already exists')
        // console.error('File with the same name already exists');
        return;
      }
      // const label = getNamesFromIds(selectedBrandboxOption?.option_id, dropdownReducer.asset_options)?.name
      //   console.log(label)
      //   console.log(assetReducer?.all_assets)
      //   const files = assetReducer?.all_assets?.find( option => option.label == label)?.files
      //   console.log(files, '----------------------------------------files--------------------------------')
        const payload = {
            label_id: selectedBrandboxOption.option_id,
            asset_file: event.target.files[0]
        }
        // console.log(payload, 'payload--------------------')
        // createAsset(payload, dispatch,  resetSelectedBrandboxOptions)
     }

     const resetSelectedBrandboxOptions =()=>{
      setSelectedBrandboxOption({option_id: ''})
      setExpanded('')
     }

    //  useEffect(()=>{
       
    //  },[selectedBrandboxOption.option_id])
     

     
useEffect(()=>{
  checkTokenSession(dispatch)
  getAllAssets(dispatch, navigate) 
  if(dropdownReducer.asset_options?.length === 0 ){
    getAssetOptionsList(dispatch)
  }

},[])

    // useEffect(()=>{
    //   if( commonReducer?.company_name === null || commonReducer?.company_name === ''){    
    //     dispatch(setAssetState({brand_name_popup: true}))
    //     // getAllAssets(dispatch, navigate)
    //   }else{
    //     getAllAssets(dispatch, navigate)  
    //   }
    // },[commonReducer?.company_name])

  useEffect(()=>{
      setAssets(assetReducer?.selected_asset_files)
      setSearchedArray(assetReducer?.selected_asset_files)
  },[assetReducer?.selected_asset_files])

    

  return (
    <AuthLayout uploadProps = {props.uploadProps} sx={{ padding: '2.1% 3.4% 2.1% 3.4%'}}>
        <Header/>
        <Box px={{xs:1,sm:2,md:3}} pr={{xs:1,sm:6}} className='flex col' mt={2} sx={{}}>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', color:'black', mb:2, cursor:'pointer' }} gap={1}>
                    {/* <IconButton  onClick={()=>{navigate(-1)}}> */}
                      <KeyboardBackspaceOutlinedIcon onClick={()=>{navigate(-1)}} sx={{color:'black',}}/>
                    {/* </IconButton> */}
                    <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'14px',xl:'20px'}}}>Back</Typography>
                  </Box>
            <Box className='flex j-between' mb={3} sx={{flexDirection:{xs:'column', sm:'row'}}}>
                <Box className='flex a-center' gap={4} >
                
                  <Box>
                    <Typography sx={{color:'#000000', fontFamily:'Rubik', fontWeight:600, fontSize:{xs:'16px',md:'18px',lg:'20px',xl:'30px'}}}>Brandbox</Typography>
                    <Typography sx={{color:'#000000', fontFamily:'Rubik',  fontSize:{xs:'11px',md:'12px',lg:'13px',xl:'20px'}}}>Brand guidelines, color palette, and branded assets: find all your branded assets here!</Typography>
                  </Box>
                    {/* <Box id="search" >
                        <Input
                        value={searchTerm}
                        onChange={handleSearch}
                        inputProps={{ maxLength: 100 }}
                        sx={{
                          //  minWidth: '160px',
                            borderBottom: 2,
                            borderColor: "#333333",
                            input: {
                                fontSize: { xs: '12px', md: '13px', lg: '14px', xl: '20px' },
                                fontWeight: 400,
                                color: "#000000",
                            },
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton sx={{paddingBottom:1, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'17px',xl:'24px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                    </Box> */}
                </Box>
                <Box className='flex a-center' gap={2} sx={{justifyContent:{xs:'center', sm:'end'}, mt:{xs:3, sm:0}}}>
                        {/* <Box sx={{width:'150px'}}>
                        <CustomAutocomplete
                            id="event_type"
                            options={dropdownReducer.asset_options}
                            onFocus={() => {
                            setBrandBoxOptionValidationFlag(false)
                            }}
                            onBlur={() => setBrandBoxOptionValidationFlag(true)}
                            required
                            errorFlag= {brandboxOptionValidationFlag && getErrorMsg("brandbox_option", selectedBrandboxOption?.option_id) != ""}
                            // errorMsg= {getErrorMsg("brandbox_option", selectedBrandboxOption?.option_id)}
                            errorMsg={errorText}
                            value={getNamesFromIds(selectedBrandboxOption?.option_id, dropdownReducer.asset_options)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setSelectedBrandboxOption({option_id: newValue.id})
                                setBrandBoxOptionValidationFlag(false)
                                setErrorText('')
                                // console.log(newValue, 'new value----------------')
                            }}
                            label=""
                            placeholder='Assets'
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.name : ""
                            }
                            msgColor='red'
                        />
                        </Box> */}
                 

                          {/* <Box >
                                <input
                                    accept={acceptType}
                                    id="contained-button-file"
                                    onClick={event => event.target.value = ''}
                                    style={{display:'none'}}
                                    type="file"
                                    multiple
                                    onChange={(event, newValue) => uploadFileHandler(event, newValue)}
                                    disabled={selectedBrandboxOption.option_id === ''}
                                />
                            <label htmlFor="contained-button-file">
                                <Box  variant='body1'    
                                style={{
                                borderRadius: '5px',
                                border: "none",
                                textTransform:'none',
                                backgroundColor:'#00CEFF',
                                display:'flex',
                                justifyContent:"center",
                                alignItems:'center',
                                opacity: selectedBrandboxOption.option_id === ''? 0.6 : 1 ,
                                }}
                                sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:"32px"}, width:{xs:'90px',sm:'110px',md:'130px',lg:'140px',xl:'165px'}, fontFamily:'Rubik', cursor:'pointer'}} >Upload</Box>
                            </label>  
                        </Box> */}

                        <Box>

                        <CustomButton 
                            btnText='Upload' 
                            onClick={()=>dispatch(setAssetState({upload_files_popup:true}))} 
                            type='button'
                            color='secondary'
                            variant='contained'
                            style={{
                                borderRadius: '5px',
                                border: "none",          
                                textTransform:'none',
                                // backgroundColor:theme.palette.secondary.main
                                }}
                            sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"36px"}, width:'6.1rem', fontFamily:'Rubik', marginBottom:{xs:'0px',md:'10px',lg:'11px',xl:'12px'},}}
                            />
                          {/* <CustomButton  color='secondary'   variant='contained'  btnText='Upload' type='button' onClick={()=>dispatch(setAssetState({upload_files_popup:true}))}  style={{borderRadius:5, border:'none', fontSize:'12px', padding:'10px', width:'6.1rem', fontWeight:600, textTransform:'none', height:'20px',fontFamily:'Rubik' }}/> */}
                        </Box>
           
                    {/* <CustomSortOptions
                    sortByOptions={mediaFilesSortOptions}
                    selectedSortbyValue={selectedSortbyValue}
                    setSelectedSortbyValue={setSelectedSortbyValue}
                    /> */}
                </Box>
            </Box>
            {/* {(allMediaFilesLoading == true) && <Typography variant='h3'>Loading...</Typography>} */}
            {/* <BrandboxOptions
                files={sortedFiles}
                selectedBrandboxOption={selectedBrandboxOption.option_id}
                expanded={expanded}
                setExpanded={setExpanded}
            /> */}
            {isMobileView ? <MobileTable
                files={sortedFiles}
                selectedBrandboxOption={selectedBrandboxOption.option_id}
                expanded={expanded}
                setExpanded={setExpanded}
            /> : <BrandboxOptions
                files={sortedFiles}
                selectedBrandboxOption={selectedBrandboxOption.option_id}
                expanded={expanded}
                setExpanded={setExpanded}
            />}
            
     
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
        //   open={createZippedMediaStatus == 'loading' }
        >
          <Typography>Please wait while we prepare your files for download...</Typography>
        </Backdrop>

       { assetReducer.upload_files_popup && <UploadFilesPopup uploadProps = {props.uploadProps} />}
    </AuthLayout>
  )
}

export default UserBrandbox