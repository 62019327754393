import React, { useEffect } from 'react'
import useCustomTheme from "../../theme/useCustomTheme";
import { makeStyles } from "@mui/styles";
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { updateState } from '../../redux/commonSlice';
import { PERMISSION } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import FilmingTutorialCard from '../events/components/FilmingTutorialCard';
import GuestUserInfoPopup from './components/GuestUserInfoPopup';
import GuestEventDetailCard from './components/GuestEventDetailCard';
import GuestRequestLog from './components/GuestRequestLog';
import PopupVideoPlayer from '../../components/PopupVideoPlayer';
import SubmitChangeRequestTutorialPopup from '../completedvideo/components/SubmitChangeRequestTutorialPopup';
import GuestVideoPlayer from './components/GuestVideoPlayer';
import GuestUserCard from './components/GuestUserCard';
import EventCancelledPage from './components/EventCancelledPage';
import useGuestReviewActions from './useGuestReviewActions';
import MenuIcon from '@mui/icons-material/Menu';
import luciLogo from "../../assets/luciLogo2.png"
import poweredBy from "../../assets/poweredBy.svg"
import { setGuestReviewState } from '../../redux/guestSlice';
import DrawerEventDetails from './components/DrawerEventDetails';
import CustomCard from '../events/components/CustomCard';

const GuestReviewPage = () => {
    const classes = useStyles();
    const [theme ] = useCustomTheme()
    const dispatch = useDispatch()
    const guestReducer = useSelector(state=> state.guestReducer)
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
    const themeReducer = useSelector((state) => state.root.themeReducer);
    const deviceInfoSlug = localStorage.getItem('device_info_slug')
    const {getDeviceInfo, createDeviceInfo} = useGuestReviewActions()
    const systemInfo = navigator
    const platform = navigator.userAgentData.platform
    const isMobileView = useMediaQuery('(max-width:599px)');
    const isTabView = useMediaQuery('(max-width:959px)');
    const brandLogo = themeReducer?.brand_logo

    useEffect(()=>{
      if(!!deviceInfoSlug){
        getDeviceInfo({slug: deviceInfoSlug})
       //  console.log(deviceInfoSlug)
      }else{
        createDeviceInfo({system: platform})
       //  console.log(systemInfo)
      }
    },[])

  return (
    <>
    {guestReducer?.event_details?.cancel === true || !guestReducer?.show_guest_revision_page ? <>
        <EventCancelledPage/>
    </> : (guestReducer?.guest_reviewer_page_loading == true || guestReducer?.get_event_details_loading == true) ? <>
      <Box className='flex a-center j-center' sx={{maxHeight:'100vh', maxWidth:'100vw',height:'100vh', width:'100vw'}}>
          <CircularProgress />
      </Box>   
    </>: <>
    
    <Box container className={classes.outerContainer} sx={{ padding: '2.5% 3.9% 2.1% 3.9%'}} >
         
           <Box gap={2} className='flex row a-start' sx={{justifyContent : isTabView ? 'space-between' : 'end'}}>
               {isTabView && <Box className='flex a-center' sx={{justifyContent:'flex-start', gap:1}}>
                  <Box   className='flex col' sx={{maxWidth:'100px', minWidth:'80px'}}>
                    <img src={!!brandLogo ? brandLogo : luciLogo} style={{maxWidth:'70px', maxHeight:'35px'}} />
                    {
                      !!brandLogo && <Box textAlign='right' width='100%' mt={1}><img src={poweredBy}  width='60%'/></Box> 
                    }
                    </Box>
                    {/* <MenuIcon onClick={openEventDetailsHandler} sx={{cursor:'pointer'}}/> */}
               </Box>}
               <GuestUserCard/>
            </Box>
          <Box container className={classes.container} sx={{ minWidth:  !isTabView && '860px'}}>
           {!isTabView && <Box className={classes.leftContainer}>
              <Box sx={{height:{xs:'400px',sm:'400px',md:'58vh',lg:'62vh',xl:'70vh'}, marginBottom:{xs:'15px',md:'20px',lg:'23px',xl:'35px'}}}>
                 <GuestEventDetailCard eventData/>
              </Box>
              <Box  sx={{height:{xs:'105px',sm:'105px',md:'16.1vh',lg:'18.2vh',xl:'16.1vh'}}} >
                <FilmingTutorialCard onClick= {()=> dispatch(updateState({play_tutorial: true})) } clickable = {true} />
              </Box>      
            </Box>}
  
        
        <Box className={!isTabView && classes.rightContainer}>
        <Box style={{display:'flex', gap:'2.34%'}} sx={{ marginBottom:{xs:'15px',sm:'15px',md:'15px',lg:'20px',xl:'30px'},  marginTop:{xs:'10px', sm:'10px', md:'12px',lg:'14px',xl:'23px'}, width:'100%', }}>
                {isTabView && <>
                   <CustomCard style={{width:'100%', padding:'5px', paddingLeft:'10px'}}>
                      <Box className='flex' gap={{xs:1,sm:2}} sx={{flexDirection:{xs:'column', sm:'row'}, padding:{sm:'5px'}}}>
                        <Box mb={{xs:0.5,md:0.8,   xl:1.3}} sx={{ display:'flex', gap:0.5 }}>
                            <Typography sx={{fontWeight:'normal',  fontSize: {xs:'12px',sm:'13px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', minWidth:'70px'}}>Lucivideo Name -</Typography>
                            {/* <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'11px',sm:'12px',md:'9px',lg:'10.67px',xl:'16px', wordWrap: 'break-word'}, textTransform:'capitalize', fontFamily:'Rubik'}}>{guestReducer?.event_details?.name}</Typography> */}
                            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'11px',sm:'12px',md:'9px',lg:'10.67px',xl:'16px', wordWrap: 'break-word'}, textTransform:'capitalize', fontFamily:'Rubik'}}> {guestReducer?.event_details?.name}</Typography>
                        </Box>

                        <Box mb={{xs:0.5, md:0.8,  xl:1.3}}  sx={{display:'flex' , gap:0.5}}>
                            <Typography  sx={{color:'#878887' , fontSize: {xs:'12px',sm:'13px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', minWidth:'70px'}}>Event Type -</Typography>
                            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'11px',sm:'12px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{guestReducer?.event_details?.event_type}</Typography>
                        </Box>
                      </Box>
                      </CustomCard>
                    </>}

         </Box>
 
              <Box sx={{display:'flex',
              height: {xs:'77vh',sm:'80vh',md:'59%',lg:'92.5%',xl:'94%'},
                width:'100%', 
               backgroundColor:'#ffffffbf', 
               flexDirection: isMobileView ? 'column' : 'row',
              boxShadow:'0px 5px 15px #00000029', borderRadius:'10px', alignItems:!isTabView ? 'center' : 'flex-start' ,pt:3}} >
                   
              
                    <Box sx={{height:'100%', display:"flex", alignItems: 'flex-start' , paddingTop:'10px', justifyContent:'center', overflowY:'auto',  width: {xs:'100%', sm:'65%',md:'70%'}, minWidth : isTabView && '320px', minHeight:isMobileView && '250px', maxHeight:{xs:'42%', md:'100%'}}}>
                        <GuestVideoPlayer  src={ guestReducer?.event_details?.revision_video_url}/>
                    </Box>
                     <Box sx={{height:'100%', mr: {sm:1 ,md:3}, width:{xs:'100%', sm:'35%', md:'30%'},  maxHeight:{xs:'58%',md:'768px'} ,px:2 }}>
                        <GuestRequestLog/>
                    </Box>
              </Box>
        
        </Box>  
          </Box> 

      {guestReducer.guest_user_details_popup && <GuestUserInfoPopup/>}
      
      { commonReducer.play_tutorial == true && <PopupVideoPlayer />}

      {reviewEventReducer.open_change_request_tutorial_popup == true && <SubmitChangeRequestTutorialPopup />}
      {guestReducer.open_event_details_drawer == true && <DrawerEventDetails />}
    </Box >
    </>}
  
    </>
  )
}

export default GuestReviewPage


const useStyles = makeStyles((theme) => ({
    outerContainer:  {
       fontFamily: 'Rubik',
       height: '100%',
       minHeight: '100vh',

       background: `linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670, ${theme.palette.secondary.main}20 , ${theme.palette.secondary.main}50 80%,  ${theme.palette.primary.main}60 , ${theme.palette.primary.main}60 )`,

       display: 'flex',
       flexDirection: 'column',
       overflowY: 'scroll !important',
       scrollbarWidth: 'none',
       msOverflowStyle: 'none',
       '&::-webkit-scrollbar': {
         display : 'none'
       },
   },
     container: {
      display:'flex',
      width:'100%',
     
      height:'85vh',
     // height:'100%',  
  },
    leftContainer: {
      width:'21.1%',
       display:'flex',
       flexDirection:'column',
       justifyContent: 'flex-start',
       marginBottom:'41px',  
      // paddingLeft:'2.24%',
       height:'100%',
     
 },
      rightContainer:{
      width:'77.2%',
      display:'flex',
      flexDirection:'column',
      marginBottom:'51px',
      marginLeft:'1.7%',
      height:'100%',
     
  },
    fileBox: {
        width: '100%',
        display: 'flex',
        paddingLeft: '1.6%'
    }
}));